<template>
    <div ref="document">
        <CCard class="w-100">
            <CCardHeader>
                <h2>Area</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                     
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>
            
                <areaCode-grid :key="gridReload" :editClick="editClick" :deleteClick="deleteClick"/>
                <areaCode-form ref="areaCodeForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import AreaCodeGrid from '../AreaCode/Grid/AreaCodeGrid.vue';
import AreaCodeForm from '../AreaCode/Component/AreaCodeForm.vue';
import AreaCodeServices from '../AreaCode/Script/AreaCodeServices';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'AreaCode',
    components: {
        'areaCode-grid': AreaCodeGrid,
        'areaCode-form': AreaCodeForm,
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Area Code');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.areaCodeForm.addClick();
        },
        editClick(itemData, view){
            this.$refs.areaCodeForm.editClick(itemData, view);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }

                if (result.isConfirmed == true) {
                    AreaCodeServices.deleteQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>